import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import Layout from '../components/layout';
import { replaceVariables } from '../utils/replaceDate';

const AgencyTemplate = ({ data, pageContext, location: { pathname } }) => {
  const agency = data.agency;

  return (
    <Layout
      hideHeader={true}
      isDirectoryPage={true}
      footerBgClass="bg-white"
      meta={{
        seo: {
          title: replaceVariables(`${agency.name} - Agency Profile`),
          description: replaceVariables(`${agency.shortDescription}`),
        },
        path: pathname,
      }}
    >
      <div className="agency-profile-page">
        <section>
          <div className="container">
            <div className="row align-items-center mb-4">
              <div className="col-auto">
                <div style={{ width: '100px', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', border: '1px solid #e0e0e0', borderRadius: '8px' }}>
                  <GatsbyImage
                    image={agency.logo?.gatsbyImageData}
                    alt={agency.name}
                    className="m-1"
                    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                  />
                </div>
              </div>
              <div className="col">
                <h1 className="display-4 mb-0">{agency.name}</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5">
                {agency.thumbnail && (
                    <div className='mb-3' style={{ position: 'relative', width: '100%', paddingTop: '56.25%' }}>
                        <GatsbyImage
                        image={agency.thumbnail.gatsbyImageData}
                        alt={`${agency.name} thumbnail`}
                        className="rounded"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                        }}
                        />
                    </div>
                )}

                <h3>Details</h3>
                <div className="d-flex flex-column mb-2">
                  <div className="mb-2"><span className="text-secondary">Min. Project Size:</span> <span className="font-weight-bold">{agency.minProjectSize || 'N/A'}</span></div>
                  <div className="mb-2"><span className="text-secondary">Avg. Hourly Rate:</span> <span className="font-weight-bold">{agency.avgHourlyRate ? `$${agency.avgHourlyRate}/hr` : 'N/A'}</span></div>
                  <div className="mb-2"><span className="text-secondary">Employees:</span> <span className="font-weight-bold">{agency.employees || 'N/A'}</span></div>
                  <div className="mb-2"><span className="text-secondary">Location:</span> <span className="font-weight-bold">{`${agency.city}, ${agency.country}`}</span></div>
                  <div className="mb-2"><span className="text-secondary">Founded:</span> <span className="font-weight-bold">{agency.founded || 'N/A'}</span></div>
                </div>

              </div>
              <div className="col-md-7 lead">
                <h2>About</h2>
                <ReactMarkdown>{agency.longDescription}</ReactMarkdown>
                
                {/* <h3>Categories</h3>
                <ul>
                  {agency.category.map((cat, index) => (
                    <li key={index}>{cat.categoryName}</li>
                  ))}
                </ul> */}
              </div>
            </div>
            <div className='row'>
              <div className="col">
                <div className="card card--no-hover bg-primary text-white text-center">
                  <div className="card-body">
                    <span className="badge badge-light text-primary-3 h4 mb-2 py-1 px-3">FREE UX Session</span>
                    <h3 className='text-white mb-1'>Not sure where to start?</h3>
                    <p className='text-white lead'>Get help with your project during our free UX session</p>
                    <button className="btn btn-light btn-lg mb-2">Book a call</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default AgencyTemplate;

export const query = graphql`
  query AgencyTemplate($id: String!) {
    agency: datoCmsAgency(id: { eq: $id }) {
      id
      name
      shortDescription
      longDescription
      logo {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
      thumbnail {
        gatsbyImageData(width: 800, layout: CONSTRAINED)
      }
      minProjectSize
      avgHourlyRate
      employees
      city
      state
      country
      category {
        categoryName
      }
      founded
      slug
    }
  }
`;
